import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`El capell d’aquest bolet és cònic, de fins a 4 cm de diàmetre i presenta un mamelló en forma de punxa. Destaca el color ocre taronjós i rogenc d’aquest bolet respecte a altres del mateix gènere que són més apagats. Les làmines, del mateix color que el capell són d’adnades a lliures i el peu, més llarg que el diàmetre del capell, presenta la base en forma de bulb. Les espores són marrons en massa, més o manco el·líptiques, de 9-12 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      